import type { History } from "history";
import { createBrowserHistory } from "history";
import type { ReactNode } from "react";
import { useEffect } from "react";

import { HistoryProvider } from "@shared/context";
import type { BrowserRouterProps, HistoryRouterProps } from "@shared/react-router-dom";
import { unstable_HistoryRouter as Router } from "@shared/react-router-dom";

interface Props extends BrowserRouterProps {
  history?: History;
  children: ReactNode;
}

const BrowserRouter = ({ children, history: initialHistory, ...props }: Props) => {
  const history = initialHistory ?? createBrowserHistory(props);

  useEffect(() => {
    const unlisten = history.listen(({ location }) => {
      if (typeof window.ga === "function") {
        window.ga("set", "page", location.pathname + location.search);
        window.ga("send", "pageview");
      }

      window.dataLayer?.push({ event: "pageview" });
    });

    return () => unlisten();
  }, []);

  return (
    // Unfortunately the only way to silence the type errors here unless we ts-ignore is to do this
    // see https://github.com/remix-run/react-router/issues/9422#issuecomment-1301954272
    <Router {...({ ...props, history } as unknown as HistoryRouterProps)}>
      <HistoryProvider history={history}>{children}</HistoryProvider>
    </Router>
  );
};

export { BrowserRouter };
